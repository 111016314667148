<template>
  <v-card
    elevation="4"
    class="data-table"
    :class="canBeSelected ? 'data-table--selectable' : ''"
  >
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="itemsLoading ? [] : items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="searchTerm"
      :sort-by="sortBy.value"
      :sort-desc="sortDesc"
      hide-default-footer
      hide-default-header
      class="elevation-0 custom-data-table"
      :server-items-length="itemsTotal"
    >
      <template v-if="itemsLoading" v-slot:body>
        <template v-if="$vuetify.breakpoint.lgAndDown">
          <config-table-item-skeleton-mobile v-for="item in 5" :key="item" />
        </template>
        <template v-else>
          <config-table-skeleton-sort />
          <config-table-skeleton-item />
        </template>
      </template>

      <template v-slot:no-data>
        <data-iterator-no-results-container
          v-if="searchTerm"
          @clearAllFilters="clearFilters()"
          :searchTerm="searchTerm"
        />
      </template>

      <template
        v-if="$vuetify.breakpoint.width >= 1400 && !itemsLoading"
        v-slot:header="{ isMobile, props, on }"
      >
        <table-custom-sort
          :isMobile="isMobile"
          :props="props"
          :v-on="on"
          v-on="$listeners"
        />
      </template>

      <template v-slot:item.name="{ item }">
        <span class="bold">{{ item.name ? item.name : "---" }}</span>
      </template>

      <template v-slot:item.value="{ item }">
        <span>{{
          item.value
            ? item.type !== "secret"
              ? item.value
              : hideSecret(item.value)
            : "---"
        }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex actions-row">
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                @click="$emit('action-button-edit', item)"
              >
                <v-icon>$edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.config.edit') }}</span>
          </v-tooltip>
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                @click="$emit('action-button-delete', item)"
              >
                <v-icon>$thrash</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.config.delete') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template
        v-if="$vuetify.breakpoint.width < 1400"
        v-slot:item="{ item, headers }"
      >
        <tr>
          <td class="w-100 px-0">
            <div class="mobile-table-item">
              <div class="mobile-table-item__values">
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.name') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.name }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[1])"
                  >
                    {{ $t('table.header.value') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{
                      item.value
                        ? item.type !== "secret"
                          ? item.value
                          : hideSecret(item.value)
                        : "---"
                    }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[2])"
                  >
                    {{ $t('table.header.type') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.type }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__actions">
                  <v-tooltip
                    transition="custom-tooltip"
                    open-delay="150"
                    bottom
                    z-index="99"
                    offset-overflow
                    nudge-bottom="4px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        small
                        @click="$emit('action-button-edit', item)"
                      >
                        <v-icon>$edit</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('button.config.edit') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    transition="custom-tooltip"
                    open-delay="150"
                    bottom
                    z-index="99"
                    offset-overflow
                    nudge-bottom="4px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        icon
                        small
                        @click="$emit('action-button-delete', item)"
                      >
                        <v-icon>$thrash</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('button.config.delete') }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:footer>
        <div>
          <data-iterator-footer
            :page="page"
            :numberOfPages="numberOfPages"
            :possibleItemsPerPage="possibleItemsPerPage"
            :listStyle="listStyle"
            :itemsPerPage="itemsPerPage"
            :itemsTotal="itemsTotal"
            v-on="$listeners"
          >
          </data-iterator-footer>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DataIteratorMixin from "../../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../../mixins/CustomTableMixin";

import DataIteratorFooter from "../DataIteratorFooter.vue";
import TableCustomSort from "../TableCustomSort.vue";

import ConfigTableSkeletonItem from "./ConfigTableSkeletonItem.vue";
import ConfigTableSkeletonSort from "./ConfigTableSkeletonSort.vue";
import ConfigTableItemSkeletonMobile from "./ConfigTableItemSkeletonMobile.vue";
import DataIteratorNoResultsContainer from "../DataIteratorNoResultsContainer.vue";

export default {
  components: {
    DataIteratorFooter,
    TableCustomSort,
    ConfigTableSkeletonItem,
    ConfigTableSkeletonSort,
    ConfigTableItemSkeletonMobile,
    DataIteratorNoResultsContainer,
  },
  data: function () {
    return {
      canBeSelected: false,
    };
  },
  mixins: [DataIteratorMixin, CustomTableMixin],
  props: {
    headers: Array,
    itemsTotal: Number,
    itemsLoading: Boolean,
  },
  methods: {
    clearFilters: function () {
      this.searchTerm = "";
    },
    hideSecret: function (secret) {
      return "•".repeat(secret.length + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  margin-left: 10px;
  @media (max-width: 1183px) {
    margin-left: 0px;
    margin-right: 10px;
  }
}
</style>
